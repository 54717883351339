<template>
  <el-table :data="dataclassAttrData.customFields" class="table">
    <el-table-column prop="name" label="属性名称" />
    <el-table-column prop="fieldTypeName" label="属性类型" />
  </el-table>
</template>

<script>
export default {
  name: 'DataclassAttrAttr',
  data() {
    return {};
  },
  computed: {
    dataclassAttrData: {
      get() {
        return this.$store.state.data.dataclassAttrData;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrData', val);
      }
    }
  }
};
</script>

<style lang="less" scoped>
.table {
  border: 1px solid #507cff;
  border-radius: 4px;
  /deep/ .el-table {
    &__header {
      .el-table__cell {
        height: 40px;
        line-height: 40px;
        background-color: #f8f9fd;
        color: #333;
        font-size: 18px;
        &:first-child {
          padding-left: 20px;
        }
      }
    }
    &__body {
      .el-table__cell {
        height: 36px;
        line-height: 36px;
        background-color: #fff;
        border-bottom: none;
        color: #666666;
        font-size: 16px;
        &:first-child {
          padding-left: 20px;
        }
      }
    }
  }
}
</style>
