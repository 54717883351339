<template>
  <el-dialog
    title="导入数据"
    width="28.90625vw"
    :visible.sync="datasetImportDataDialogVisible"
  >
    <el-form class="form" label-position="left" label-width="6.25vw">
      <el-form-item label="导入位置：">
        <el-input
          class="datasetIpt"
          v-model="form.datasetId"
          placeholder="请选择数据集"
          readonly
        >
          <template #append>
            <el-button type="primary">选择</el-button>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="数据文件：">
        <el-upload class="upload-wrap" action>
          <div class="upload">
            <img class="upload-img" src="~a/workbench/upload-2.png" alt="" />
            <p class="upload-text">从本地文件库上传</p>
          </div>
        </el-upload>
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button
            class="form-btn cancel"
            @click="datasetImportDataDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="confirm">确定</Button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'DatasetImportDataDialog',
  data() {
    return {
      form: {
        datasetId: '',
        file: ''
      }
    };
  },
  computed: {
    datasetImportDataDialogVisible: {
      get() {
        return this.$store.state.data.datasetImportDataDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setDatasetImportDataDialogVisible', val);
      }
    }
  },
  methods: {
    confirm() {}
  }
};
</script>

<style lang="less" scoped>
// 数据集位置选择
.datasetIpt {
  /deep/ .el-input {
    &-group__append {
      .el-button {
        width: 80px;
        height: 40px;
        background: #507cff;
        border-radius: 0px 6px 6px 0px;
        color: #fff;
        font-size: 18px;
      }
    }
  }
}
// 文件选择器
.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 166px;
  background: #ffffff;
  border: 1px solid #507cff;
  border-radius: 4px;
  text-align: center;
  &-wrap {
    width: 100%;
    /deep/ .el-upload {
      width: 100%;
    }
  }
  &-img {
    width: 62px;
    height: 51px;
    margin-bottom: 12px;
  }
  &-text {
    color: #333;
    font-size: 16px;
  }
}
</style>
