<template>
  <el-dialog
    title="添加在线服务"
    :visible.sync="onlineServiceDialogVisible"
    width="26.45833vw"
  >
    <el-form class="form" label-width="4.8vw" :model="form" :rules="rules">
      <el-form-item label="位置：">
        <el-select v-model="form.datasetId">
          <el-option
            v-for="option in datasetOptions"
            :key="'dataset_' + option.id"
            :value="option.id"
            :label="option.name"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="名称：" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="请输入在线服务名称"
          clearable
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="URL：" prop="url">
        <el-input
          v-model="form.url"
          placeholder="请输入在线地图的URL"
          clearable
        />
        <p class="tip">示例：http://t0.tianditu.gov.cn/vec_c/wmts</p>
      </el-form-item>
      <el-form-item label="配置：">
        <el-radio-group v-model="configType">
          <el-radio-button :label="1"> 认证 </el-radio-button>
          <el-radio-button :label="2"> 自定义参数 </el-radio-button>
          <el-radio-button :label="3"> Header </el-radio-button>
        </el-radio-group>
        <div class="form-content-bottom">
          <!-- 认证 -->
          <template v-if="configType === 1">
            <el-select
              class="authenticationTypeSelect"
              v-model="form.authenticationType"
            >
              <el-option
                v-for="option in authenticationTypes"
                :key="'authenticationType_' + option.id"
                :value="option.id"
                :label="option.name"
              />
            </el-select>
            <!-- 认证参数 -->
            <div class="authentication-params">
              <template v-if="form.authenticationType === 1">
                <div class="authentication-params-item">
                  <div class="authentication-params-item-label">
                    对应认证方式
                  </div>
                  <div class="authentication-params-item-content">参数值</div>
                </div>
                <div class="authentication-params-item">
                  <div class="authentication-params-item-label">
                    对应认证方式
                  </div>
                  <div class="authentication-params-item-content">参数值</div>
                </div>
              </template>
              <template v-else-if="form.authenticationType === 2"></template>
              <template v-else>
                <p class="authentication-params-none">
                  无需认证即可使用该地图服务
                </p>
              </template>
            </div>
          </template>
          <!-- 自定义参数 -->
          <template v-else-if="configType === 2">
            <el-table class="params-table" :data="paramsArr" height="6.25vw">
              <el-table-column label="参数名">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.name" />
                </template>
              </el-table-column>
              <el-table-column label="参数值">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.value" />
                </template>
              </el-table-column>
              <el-table-column label="说明">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.explain" />
                </template>
              </el-table-column>
              <el-table-column width="50px">
                <template slot-scope="scope">
                  <button
                    type="button"
                    class="btn"
                    v-if="scope.$index === 0"
                    @click="addParam"
                  >
                    <i class="iconfont icon-add"></i>
                  </button>
                  <button
                    type="button"
                    class="btn"
                    v-else
                    @click="reduceParam(scope.$index)"
                  >
                    <i class="iconfont icon-reduce"></i>
                  </button>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <!-- Header -->
          <template v-else-if="configType === 3"></template>
        </div>
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button class="form-btn cancel" @click="cancel">取消</Button>
          <Button class="form-btn confirm" @click="confirm">确定</Button>
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'AddOnlineServiceDialog',
  data() {
    return {
      datasetOptions: [],
      configType: 1,
      authenticationTypes: [
        { name: '无需认证', id: '' },
        { name: '认证1', id: 1 },
        { name: '认证2', id: 2 }
      ],
      form: {
        datasetId: '',
        name: '',
        url: '',
        authenticationType: '',
        authentication: '',
        params: {},
        header: {}
      },
      paramsArr: [{ name: '', value: '', explain: '' }],
      rules: {
        name: [
          {
            required: true,
            message: '请输入在线服务名称',
            trigger: 'blur'
          }
        ],
        url: [
          {
            required: true,
            message: '请输入在线地图的URL',
            trigger: 'blur'
          },
          { type: 'url', message: '请参考示例填写正确的在线服务链接' }
        ]
      }
    };
  },
  computed: {
    onlineServiceDialogVisible: {
      get() {
        return this.$store.state.data.onlineServiceDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setOnlineServiceDialogVisible', val);
      }
    }
  },
  methods: {
    // 添加自定义参数
    addParam() {
      this.paramsArr.push({ name: '', value: '', explain: '' });
    },
    reduceParam(index) {
      this.paramsArr.splice(index, 1);
    },
    cancel() {
      this.onlineServiceDialogVisible = false;
    },
    confirm() {}
  }
};
</script>

<style lang="less" scoped>
.form {
  .tip {
    margin-top: 10px;
    line-height: initial;
    color: #f06012;
    font-size: 14px;
  }
  &-content {
    &-bottom {
      box-sizing: border-box;
      height: 140px;
      margin-top: 10px;
      padding: 10px;
      border: 1px solid #507cff;
      border-radius: 4px;
    }
  }
  .authenticationTypeSelect {
    line-height: initial;
    /deep/ .el-input {
      &__inner {
        height: 32px;
        line-height: 32px;
        color: #333;
        font-size: 14px;
      }
      &__icon {
        line-height: inherit;
      }
    }
  }
  // 认证方式对应参数
  .authentication-params {
    color: #333;
    font-size: 14px;
    padding: 0 10px;
    margin-top: 10px;
    &-none {
      margin-top: 10px;
      text-align: center;
    }
    &-item {
      display: flex;
      margin-bottom: 20px;
      line-height: initial;
      &:last-child {
        margin-bottom: 0;
      }
      &-label {
        width: 100px;
      }
    }
  }
  // 自定义参数表格
  .params-table {
    &::before {
      background-color: #fff;
    }
    /deep/ .el-table {
      &__header {
        .el-table__cell {
          padding-bottom: 0;
        }
      }
      &__cell {
        height: 32px;
        padding-bottom: 10px;
        line-height: 32px;
        background: #fff;
        border-bottom: none;
        color: #333;
        font-size: 14px;
        .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
      }
    }
  }
  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border: 1px solid #999999;
    border-radius: 4px;
    .iconfont {
      color: #999;
      font-size: 24px;
    }
    &:active {
      color: #507cff;
      border-color: #507cff;
    }
  }
  /deep/ .el-radio {
    &-group {
      display: flex;
      align-items: center;
      border-radius: 4px 0px 0px 4px;
    }
    &-button {
      flex: 1;
      height: 40px;
      &.is-active {
        .el-radio-button__inner {
          color: #507cff;
          border-color: #507cff;
          background-color: #fff;
        }
      }
      &__inner {
        width: 100%;
        line-height: 40px;
        padding: 0;
        font-size: 16px;
      }
    }
  }
}
</style>
