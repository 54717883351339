<template>
  <!--数据类-属性-基本 -->
  <div class="info">
    <InfoItem>
      <template slot="label">
        <span style="color: red">*</span>名称：
      </template>
      <el-input
        v-model.trim="dataclassAttrData.name"
        maxlength="20"
        show-word-limit
      />
    </InfoItem>
    <InfoItem label="别称：">
      <el-input
        v-model="dataclassAttrData.alias"
        maxlength="20"
        show-word-limit
      />
    </InfoItem>
    <InfoItem label="说明：">
      <el-input
        type="textarea"
        :rows="3"
        v-model="dataclassAttrData.description"
      />
    </InfoItem>
    <div class="info-border"></div>
    <InfoItem label="类型：" :text="dataclassAttrData.dataTypeName" />

    <InfoItem label="数据集：" :text="dataclassAttrData.dataset" />
    <InfoItem label="创建时间：" :text="dataclassAttrData.createDate" />
    <InfoItem label="修改时间：" :text="dataclassAttrData.updateDate" />
  </div>
</template>

<script>
import InfoItem from 'c/basic/InfoItem';
export default {
  name: 'DataclassAttrBasic',
  components: {
    InfoItem
  },
  data() {
    return {
      info: {
        name: '数据集',
        typeName: '数据集',
        parentName: '要素根目录',
        contain: '100条数据',
        createTime: '202-07-08  14:52:00',
        updateTime: '202-07-08  14:52:00',
        size: 20.25
      }
    };
  },
  computed: {
    dataclassAttrData: {
      get() {
        return this.$store.state.data.dataclassAttrData;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrData', val);
      }
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.form-btn-group {
  justify-content: center !important;
  margin-top: 28px;
}
.info {
  /deep/.el-input {
    &__inner {
      width: 200px;
      padding-right: 40px;
    }
    &__count .el-input__count-inner {
      padding: 0 10px;
      background: transparent;
    }
  }
  /deep/.el-textarea__inner {
    width: 200px;
  }
  &-border {
    width: 374px;
    height: 1px;
    background: #d2d2d2;
    margin-bottom: 20px;
  }
}
</style>
