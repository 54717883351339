<template>
  <el-dialog
    class="dialog"
    title="属性"
    :visible.sync="dataclassAttrDialogVisible.status"
    width="19.47917vw"
  >
    <div slot="title">
      <el-row>
        <span class="el-dialog__title">属性</span>
        <el-button
          v-show="tabActive == 3"
          type="primary"
          class="el-dialog__headerbtn"
          @click="handleAttr(dataclassAttrDialogVisible.id)"
          >属性字段设置</el-button
        >
      </el-row>
    </div>
    <div class="tabs">
      <div class="tab-header">
        <el-radio-group v-model="tabActive">
          <el-radio-button label="1">基本</el-radio-button>
          <el-radio-button label="2">样式</el-radio-button>
          <el-radio-button label="3">字段</el-radio-button>
          <!-- <el-radio-button label="4">其他</el-radio-button> -->
        </el-radio-group>
      </div>
      <div class="tab-container">
        <component :is="tabComponent" />
      </div>
    </div>
    <div class="form-btn-group">
      <Button
        class="form-btn cancel"
        @click="dataclassAttrDialogVisible = false"
        >取消</Button
      >
      <Button class="form-btn confirm" @click="confirm">确定</Button>
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
import $bus from 'p/utils';
import DataclassAttrBasic from './DataclassAttrBasic';
import DataclassAttrStyle from './DataclassAttrStyle';
import DataclassAttrAttr from './DataclassAttrAttr';
import DataclassAttrOther from './DataclassAttrOther';
export default {
  name: 'DataclassAttrDialog',
  components: {
    Button
  },
  data() {
    return {
      tabActive: '1',
      // 属性数据
      tempData: []
    };
  },

  computed: {
    // 切换tab时时切换显示的组件
    tabComponent() {
      let component = '';
      switch (this.tabActive) {
        case '1':
          component = DataclassAttrBasic;
          break;
        case '2':
          component = DataclassAttrStyle;
          break;
        case '3':
          component = DataclassAttrAttr;
          break;
        case '4':
          component = DataclassAttrOther;
          break;
      }
      return component;
    },
    dataclassAttrDialogVisible: {
      get() {
        return this.$store.state.data.dataclassAttrDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrDialogVisible', val);
      }
    },
    dataclassAttrData: {
      get() {
        return this.$store.state.data.dataclassAttrData;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrData', val);
      }
    }
  },
  mounted() {
    var that = this;
    $bus.$off('refreshAttr');
    $bus.$on('refreshAttr', () => {
      that.getFormData(this.dataclassAttrData.id);
    });
  },
  methods: {
    // 获取属性数据
    getFormData(id) {
      const params = {
        id: id
      };
      this.$service.data.featureClassAttr(params).then(res => {
        if (res.data.status === 200) {
          this.$store.commit('data/setDataclassAttrData', res.data.data);
          // 保存数据后续校对
          this.tempData = JSON.parse(JSON.stringify(res.data.data));
        }
      });
    },
    // 提交表单
    confirm() {
      let data = this.dataclassAttrData;
      let tempData = this.tempData;
      if (
        data.name !== tempData.name ||
        data.alias !== tempData.alias ||
        data.description !== tempData.description ||
        data.stickGround !== tempData.stickGround
      ) {
        if (!data.name) return this.$message('请输入名称');
        const params = {
          id: data.id,
          name: data.name,
          alias: data.alias,
          description: data.description,
          datasetId: data.datasetId,
          stickGround: data.stickGround
        };
        this.$service.data.featureClassEdit(params).then(res => {
          if (res.data.status === 200) {
            this.$emit('partialRefresh');
            this.$message.success('修改成功');
            this.$store.commit('data/setDataclassAttrDialogVisible', false);
          }
        });
      } else {
        this.$store.commit('data/setDataclassAttrDialogVisible', false);
      }
    },
    // 打开属性字段设置
    handleAttr(id) {
      const data = {
        status: true,
        id: id
      };
      this.$store.commit('data/setDataclassAttrFieldDialogVisible', data);
    }
  },
  watch: {
    dataclassAttrDialogVisible: {
      immediate: true,
      handler(data) {
        if (data.id) {
          this.getFormData(data.id);
        }
        this.tabActive = '1';
      }
    }
  }
};
</script>

<style lang="less" scoped>
.dialog /deep/ .el {
  &-dialog__body {
    padding: 20px 30px;
  }
  &-radio-group {
    display: flex;
  }
  &-radio-button {
    flex: 1;
    &__orig-radio {
      &:checked + .el-radio-button__inner {
        background-color: #507cff;
        border-color: #507cff;
        box-shadow: none;
        color: #fff;
      }
    }
    &__inner {
      width: 100%;
      padding: 0;
      height: 34px;
      line-height: 34px;
      border-color: #e5e5e5;
      color: #666;
      font-size: 16px;
    }
  }
}

.tab-container {
  height: 316px;
  margin-top: 20px;
  overflow-y: auto;
  overflow-x: hidden;
}

.form-btn-group {
  justify-content: center !important;
  margin-top: 28px;
}

.el-dialog__headerbtn {
  width: 120px;
  height: 30px;
  background: #507cff;
  border-radius: 4px;
  right: 47px;
  top: 7px;
}
/deep/.el-icon-close:before {
  font-size: 24px;
}
</style>
