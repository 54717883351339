<template>
  <!--数据类-属性-样式 -->
  <div class="style">
    <!-- 图标预览 -->
    <div class="style-icon" v-if="dataclassAttrData.dataType == 1">
      <div class="style-icon-box">
        <img
          v-if="dataclassAttrData.iconStyle"
          :src="dataclassAttrData.iconStyle.url"
        />
      </div>
      <el-button class="style-btn" @click="handleEdit('icon')">
        编辑样式</el-button
      >
    </div>
    <!-- 线段预览 -->
    <div class="style-line" v-if="dataclassAttrData.dataType == 3">
      <div class="style-line-box">
        <!-- 线段预览 -->
        <div class="style-preview">
          <div
            class="style-preview-item"
            v-for="(color, index) in lineFillColor"
            :key="'1_' + index"
            :style="{ backgroundColor: color }"
          ></div>
        </div>
      </div>
      <el-button class="style-btn" @click="handleEdit('line')">
        编辑样式</el-button
      >
    </div>
    <!-- 多边形预览 -->
    <div class="style-polygon" v-if="dataclassAttrData.dataType == 5">
      <div class="style-polygon-box">
        <div class="style-polygon-box-content">
          <div class="style-polygon-box-content-top">
            <div
              class="item-width"
              v-for="(color, index) in frameFillColor"
              :key="'1_' + index"
              :style="{ backgroundColor: color }"
            ></div>
          </div>
          <div class="style-polygon-box-content-box">
            <div class="style-polygon-box-content-left">
              <div
                v-show="index > 4"
                class="item-height"
                v-for="(color, index) in frameFillColor"
                :key="'1_' + index"
                :style="{ backgroundColor: color }"
              ></div>
            </div>
            <!-- 填充内容 -->
            <div>
              <div
                class="style-polygon-box-content-color"
                :style="{ background: fillColor }"
              ></div>
            </div>
            <div class="style-polygon-box-content-right">
              <div
                v-show="index < 5"
                class="item-height"
                v-for="(color, index) in frameFillColor"
                :key="'1_' + index"
                :style="{ backgroundColor: color }"
              ></div>
            </div>
          </div>
          <div class="style-polygon-box-content-bottom">
            <div
              class="item-width"
              v-show="index > 4"
              v-for="(color, index) in frameFillColor"
              :key="'1_' + index"
              :style="{ backgroundColor: color }"
            ></div>
            <div
              class="item-width"
              v-show="index < 5"
              v-for="(color, index) in frameFillColor"
              :key="'2_' + index"
              :style="{ backgroundColor: color }"
            ></div>
          </div>
        </div>
      </div>
      <el-button class="style-btn" @click="handleEdit('polygon')">
        编辑样式</el-button
      >
    </div>
  </div>
</template>

<script>
import { ip } from 'p/urlSplice';
export default {
  name: 'DataclassAttrStyle',
  data() {
    return {
      // 线段
      lineFillColor: [],
      // 多边形
      fillColor: '',
      frameFillColor: []
    };
  },
  computed: {
    dataclassAttrData: {
      get() {
        return this.$store.state.data.dataclassAttrData;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrData', val);
      }
    }
  },
  methods: {
    // 编辑样式
    handleEdit(type) {
      const data = {
        status: true,
        id: this.dataclassAttrData.id,
        type: type,
        text: '要素数据类样式属性'
      };
      this.$store.commit('data/setDataclassAttrSytleEditDialog', data);
    },
    refreshData() {
      this.$nextTick(() => {
        const dataType = this.dataclassAttrData.dataType;
        var num = 0;
        if (dataType == 1) {
          if (!this.dataclassAttrData.iconStyle) return;
          this.dataclassAttrData.iconStyle.url = ip.urlSplice(
            this.dataclassAttrData.iconStyle.url
          );
        } else if (dataType == 3) {
          if (!this.dataclassAttrData.lineStyle) return;
          this.lineFillColor = [];
          const data = this.dataclassAttrData.lineStyle;
          for (let i = 0; i < data.fill.length; i++) {
            const element = data.fill.charAt(i);
            if (element == 1) {
              this.lineFillColor.push(data.fillColor[num]);
              num = num + 1;
            } else {
              this.lineFillColor.push('#FFFFFF00');
            }
          }
        } else if (dataType == 5) {
          if (!this.dataclassAttrData.polygonStyle) return;
          this.frameFillColor = [];
          const data = this.dataclassAttrData.polygonStyle;
          this.fillColor = this.dataclassAttrData.polygonStyle.fillColor;
          for (let i = 0; i < data.frameFill.length; i++) {
            const element = data.frameFill.charAt(i);
            if (element == 1) {
              this.frameFillColor.push(data.frameFillColor[num]);
              num = num + 1;
            } else {
              this.frameFillColor.push('#FFFFFF00');
            }
          }
        }
      });
    }
  },
  watch: {
    dataclassAttrData: {
      immediate: true,
      handler(data) {
        if (data.id) {
          this.refreshData();
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.style {
  // 图标
  &-icon {
    display: flex;
    &-box {
      width: 40px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #507cff;
      border-radius: 4px;
      display: flex;

      img {
        margin: auto;
        max-width: 30px;
        max-height: 30px;
      }
    }
  }
  // 线段
  &-line {
    display: flex;
    &-box {
      width: 200px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #507cff;
      border-radius: 4px;
      display: flex;
      // 样式预览
      .style-preview {
        display: flex;
        width: 200px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        margin: 14px 5px;
        background: #fff;
        background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
        &-item {
          flex: 1;
          height: 10px;
        }
      }
    }
  }
  // 多边形
  &-polygon {
    display: flex;
    &-box {
      width: 200px;
      height: 100px;
      background: #ffffff;
      border: 1px solid #507cff;
      border-radius: 4px;
      display: flex;
      // 样式预览
      &-content {
        margin: 5px;
        width: 190px;
        height: 90px;
        overflow: hidden;
        background-image: url(~a/workbench/materiaLibrary/block1-bg.png);
        .item-width {
          display: flex;
          width: 19px;
          height: 9px;
        }
        .item-height {
          display: flex;
          width: 9px;
          height: 19px;
        }
        &-top {
          display: flex;
        }
        &-right {
          display: flex;
          flex-direction: column;
        }
        &-bottom {
          display: flex;
          flex-direction: row-reverse;
        }
        &-left {
          display: flex;
          flex-direction: column-reverse;
        }
        &-box {
          height: 72px;
          display: flex;
          justify-content: space-between;
        }
        &-color {
          width: 172px;
          height: 72px;
          z-index: 0;
        }
      }
    }
  }
  &-btn {
    display: inline-block;
    width: 82px;
    height: 24px;
    background: #507cff;
    border-radius: 4px;
    color: #fff;
    border: none;
    margin: 10px;
  }
}
</style>
