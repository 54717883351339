<template>
  <el-dialog
    title="属性"
    :visible.sync="gridDataclassAttrDialogVisible"
    width="19.47917vw"
  >
    <div class="info">
      <InfoItem label="名称：">
        <el-input v-model.trim="info.name" maxlength="20" show-word-limit />
      </InfoItem>
      <InfoItem label="类型：" :text="info.typeName" />
      <InfoItem label="上级：" :text="info.parentName" />
      <InfoItem label="URL：" :text="info.contain" />
      <InfoItem label="是否启用：">
        <p :class="'state-' + info.state">{{ info.stateName }}</p>
      </InfoItem>
      <InfoItem label="创建时间：" :text="info.createTime" />
      <InfoItem label="修改时间：" :text="info.updateTime" />
    </div>
    <div class="form-btn-group">
      <Button
        class="form-btn cancel"
        @click="gridDataclassAttrDialogVisible = false"
        >取消</Button
      >
      <Button class="form-btn confirm" @click="handleConfirm">确定</Button>
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
import InfoItem from 'c/basic/InfoItem';
export default {
  name: 'GridDataclassAttrDialog',
  components: {
    InfoItem,
    Button
  },
  data() {
    return {
      info: {
        name: '数据集',
        typeName: '数据集',
        parentName: '要素根目录',
        contain: '100条数据',
        createTime: '202-07-08  14:52:00',
        updateTime: '202-07-08  14:52:00',
        state: 1,
        stateName: '启用'
      }
    };
  },
  computed: {
    gridDataclassAttrDialogVisible: {
      get() {
        return this.$store.state.data.gridDataclassAttrDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setGridDataclassAttrDialogVisible', val);
      }
    }
  },
  methods: {
    // 确定
    handleConfirm() {
      if (!this.info.name) {
        return this.$message({
          message: '请输入名称',
          type: 'warning'
        });
      }
      this.gridDataclassAttrDialogVisible = false;
    }
  }
};
</script>

<style lang="less" scoped>
.form-btn-group {
  justify-content: center !important;
  margin-top: 28px;
}
.state {
  &-1 {
    color: #3bbe41;
  }
}
</style>
