<template>
  <el-dialog
    title="导入数据"
    width="28.90625vw"
    :visible.sync="importDataFeatureClassDialogVisible.status"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      class="form"
      label-position="left"
      label-width="6.25vw"
    >
      <el-form-item label="数据来源：">
        <el-radio v-model="form.radio" label="1">本地文件库</el-radio>
        <el-radio v-model="form.radio" label="2">我的文件库</el-radio>
      </el-form-item>
      <el-form-item label="数据类型：" label-position="left" prop="datasetId">
        <el-select v-model="form.dataType" placeholder="请选择数据类型">
          <el-option
            v-for="item in dataTypeOptions"
            :key="item.tip"
            :label="item.name"
            :value="item.name"
          >
            <span style="float: left">{{ item.name }}</span>
            <span style="float: right; color: #999999; font-size: 13px">{{
              item.tip
            }}</span>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="数据文件：" prop="file">
        <el-input v-model="localFileName" show-word-limit disabled>
          <template slot="append">
            <i
              class="el-icon-circle-close"
              @click="handleFileClear"
              v-show="localFileName"
            />
            <el-upload
              action="d"
              :on-change="beforeUploadFunction"
              :auto-upload="false"
              :show-file-list="false"
            >
              <!-- accept=".sld" -->
              <div slot="trigger" class="selectLocale">选择</div>
            </el-upload>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item label="数据名称：" prop="name">
        <el-input v-model.trim="form.name" maxlength="20" show-word-limit />
      </el-form-item>
      <!-- <el-form-item label="保存路径：" label-position="left" prop="datasetId">
        <el-select v-model="form.datasetId" placeholder="请选择保存路径">
          <el-option
            v-for="item in datasetOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item> -->
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button
            class="form-btn cancel"
            @click="importDataFeatureClassDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="handleConfirm('ruleForm')"
            >确定</Button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'ImportDataFeatureClassDialog',
  data() {
    return {
      form: {
        name: '', // 数据名称
        file: '', // 数据文件
        dataType: '', // 数据类型
        radio: '1' // 数据来源选择
      },
      dataTypeOptions: [
        {
          name: 'Shapefile',
          tip: 'ESRI(tm)Shapefiles(*.shp)'
        },
        {
          name: 'Geopackage',
          tip: '.gpkg'
        },
        {
          name: 'GeoJSON',
          tip: '.json'
        }
      ], // 数据类型
      datasetOptions: [], // 数据集数据
      localFileName: '', // 上传的文件名称
      // 表单验证规则
      rules: {
        name: [{ required: true, message: '请输入数据名称', trigger: 'blur' }],
        file: [{ required: true, message: '请选择数据文件', trigger: 'blur' }],
        datasetId: [
          { required: true, message: '请选择保存路径', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    importDataFeatureClassDialogVisible: {
      get() {
        return this.$store.state.data.importDataFeatureClassDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setImportDataFeatureClassDialogVisible', val);
      }
    }
  },
  methods: {
    // 获取数据集数据
    getDatasetAll() {
      const params = {
        type: 1,
        workspaceId: this.$store.state.common.workspaceId
      };
      this.$service.data.datasetGetAll(params).then(res => {
        if (res.data.status === 200) {
          this.datasetOptions = res.data.data;
          if (this.form.datasetId) return;
          this.datasetId = res.data.data[0].id;
        }
      });
    },
    //导入数据文件大小和格式校验
    beforeUploadFunction(file) {
      const _name = file.name;
      const _index = _name.lastIndexOf('.');
      const _imgType = _name.substring(_index);
      if (_imgType == '.sld') {
        this.localFileName = file.name;
        this.form.file = file.raw;
      } else {
        this.$message('请选择1M以内，SLD格式文件');
        return false;
      }
      const isLt1M = file.size / 1024 / 1024 < 1;
      if (!isLt1M) {
        this.$message('请选择1M以内，SLD格式文件');
      }
      return isLt1M;
    },

    // 取消选中的数据文件
    handleFileClear() {
      this.localFileName = '';
      this.form.file = '';
    },
    // 提交请求
    handleConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const form = this.form;
          let params = new FormData();
          params.append('name', form.name);
          params.append('file', form.file);
          params.append('datasetId', form.datasetId);
          // 接口未出 featureClassAdd待更换
          this.$service.data.featureClassAdd(params).then(res => {
            if (res.data.status === 200) {
              this.$message.success(res.data.msg);
              this.importDataFeatureClassDialogVisible = false;
              // this.$emit('partialRefresh');
            }
          });
        }
      });
    }
  },
  watch: {
    importDataFeatureClassDialogVisible: {
      immediate: true,
      handler(data) {
        if (data) {
          // this.getDatasetAll();
          this.form.name = '';
          this.form.datasetId = JSON.parse(JSON.stringify(data.data.datasetId));
          this.form.radio = '1';
          this.datasetOptions = [];
          this.localFileName = '';
        }
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/.el-input {
  &__inner {
    height: 40px;
    &:focus {
      border: 1px solid #507cff;
    }
  }
  .el-icon-circle-close {
    position: absolute;
    margin: 10px 0 0 -30px;
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
  .el-icon-arrow-up:before {
    font-size: 20px;
    &:focus {
      border: 1px solid #507cff;
    }
  }
  &-group__append {
    border: 1px solid #507cff !important;
    width: 80px;
    background: #507cff;
    padding: 0;
    border-radius: 0px 6px 6px 0px;
  }
}
/deep/.el-select .el-input.is-focus .el-input__inner {
  border: 1px solid #507cff !important;
}
.selectLocale {
  width: 80px;
  height: 38px;
  color: #fff;
  line-height: 40px;
}
.form-btn-group {
  margin-top: 0 !important;
}
.uploadFile {
  /deep/.el-input__inner {
    padding-right: 35px;
  }
}
</style>
