<template>
  <!-- 添加数据类 -->
  <el-dialog
    title="新建要素数据类"
    :visible="addDataclassDialogVisible ? true : false"
    width="28.38542vw"
    @close="addDataclassDialogVisible = false"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      class="form"
      label-position="left"
      label-width="7vw"
    >
      <el-form-item label="要素类型：" prop="dataType">
        <el-radio-group v-model="form.dataType">
          <el-radio-button
            v-for="(item, index) in type"
            :key="index"
            :label="item.dataType"
          >
            <div class="radio">
              <div
                class="radio-icon"
                :style="{
                  borderColor: [form.dataType == item.dataType ? '#507cff' : '']
                }"
              >
                <i :class="'icon icon-' + item.icon"></i>
              </div>
              <span class="radio-label">{{ item.name }}</span>
            </div>
          </el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="数据类名称：" prop="name">
        <el-input
          v-model.trim="form.name"
          placeholder="新建数据类名称"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="别名：">
        <el-input
          v-model="form.alias"
          placeholder="非必填，方便识别管理"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="说明：">
        <el-input
          type="textarea"
          v-model="form.description"
          placeholder="非必填，补充说明"
          resize="none"
          maxlength="500"
          show-word-limit
        />
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button
            class="form-btn cancel"
            @click="addDataclassDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="handleConfirm('ruleForm')"
            >确定</Button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
export default {
  name: 'AddDataclassDialog',
  components: {
    Button
  },
  data() {
    return {
      form: {
        datasetId: '', // 数据集id
        dataType: '', // 要素类类型 （1：点 2：多点 3：线 4：多线 5：面 6：多面 7：圆）
        name: '', // 名称
        alias: '', // 别名
        description: '' // 说明
      },
      // 要素类类型
      type: [
        {
          dataType: '1',
          icon: 'point',
          name: '点'
        },
        // {
        //   dataType: '2',
        //   icon: 'multiPoint',
        //   name: '多点'
        // },
        {
          dataType: '3',
          icon: 'polyline',
          name: '线'
        },
        // {
        //   dataType: '4',
        //   icon: 'multiPolyline',
        //   name: '多线'
        // },
        {
          dataType: '5',
          icon: '6',
          name: '多边形'
        }
        // {
        //   dataType: '6',
        //   icon: '6',
        //   name: '多面'
        // },
        // {
        //   dataType: '7',
        //   icon: 'circle',
        //   name: '圆'
        // }
      ],
      // 表单验证规则
      rules: {
        name: [
          { required: true, message: '请输入数据类名称', trigger: 'blur' }
        ],
        dataType: [
          { required: true, message: '请选择要素类型', trigger: 'blur' }
        ]
      }
    };
  },
  created() {
    this.form.datasetId = this.addDataclassDialogVisible.id;
  },
  computed: {
    addDataclassDialogVisible: {
      get() {
        return this.$store.state.data.addDataclassDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setAddDataclassDialogVisible', val);
      }
    }
  },
  methods: {
    handleConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = this.form;
          for (var key in params) {
            params[key] === '' && delete params[key];
          }
          this.$service.data.featureClassAdd(params).then(res => {
            if (res.data.status === 200) {
              this.$message.success(res.data.msg);
              this.addDataclassDialogVisible = false;
              this.$emit('partialRefresh');
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.form {
  /deep/ .el {
    &-textarea {
      &__inner {
        height: 130px;
        padding: 13px 11px;
      }
    }
    &-radio-button {
      &__inner {
        padding: 0;
        margin-right: 30px;
        border: none;
        background: none !important;
        box-shadow: none !important;
        .radio {
          &-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 40px;
            height: 40px;
            background: #ffffff;
            border: 1px solid #c0c4cc;
            border-radius: 4px;
            &:hover {
              border-color: #507cff;
            }
          }
          &-label {
            line-height: 30px;
            height: 30px;
            color: #666;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
