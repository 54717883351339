<template>
  <div class="info">
    <InfoItem label="是否贴地：">
      <el-radio-group v-model="dataclassAttrData.stickGround">
        <el-radio :label="1">是</el-radio>
        <el-radio :label="0">否</el-radio>
      </el-radio-group>
    </InfoItem>
  </div>
</template>

<script>
import InfoItem from 'c/basic/InfoItem';
export default {
  name: 'DataclassAttrOther',
  components: {
    InfoItem
  },
  data() {
    return {
      ground: true
    };
  },
  computed: {
    dataclassAttrData: {
      get() {
        return this.$store.state.data.dataclassAttrData;
      },
      set(val) {
        this.$store.commit('data/setDataclassAttrData', val);
      }
    }
  }
};
</script>
