<template>
  <el-dialog
    title="属性"
    :visible="datasetAttrDialogVisible ? true : false"
    width="19.47917vw"
    @close="datasetAttrDialogVisible = false"
  >
    <div class="info">
      <InfoItem label="名称：">
        <el-input v-model.trim="info.name" maxlength="20" show-word-limit />
      </InfoItem>
      <InfoItem label="类型：" :text="info.typeName" />
      <InfoItem label="创建时间：" :text="info.createTime" />
      <InfoItem label="修改时间：" :text="info.updateTime" />
    </div>
    <div class="form-btn-group">
      <Button class="form-btn cancel" @click="datasetAttrDialogVisible = false"
        >取消</Button
      >
      <Button
        class="form-btn confirm"
        @click="handleConfirm(datasetAttrDialogVisible)"
        >确定</Button
      >
    </div>
  </el-dialog>
</template>

<script>
import Button from 'c/basic/Button';
import InfoItem from 'c/basic/InfoItem';
export default {
  name: 'DatasetAttrDialog',
  components: {
    InfoItem,
    Button
  },
  data() {
    return {
      info: {}
    };
  },
  created() {
    this.info = this.datasetAttrDialogVisible;
  },
  computed: {
    datasetAttrDialogVisible: {
      get() {
        return this.$store.state.data.datasetAttrDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setDatasetAttrDialogVisible', val);
      }
    }
  },
  methods: {
    // 确定
    handleConfirm(data) {
      if (!this.info.name) {
        return this.$message('名称不能为空');
      }
      const params = {
        id: data.id,
        name: this.info.name
      };
      this.$service.data.datasetModifyName(params).then(res => {
        if (res.data.status == 200) {
          this.$message.success(res.data.msg);
          this.$emit('refresh');
          this.datasetAttrDialogVisible = false;
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.form-btn-group {
  justify-content: center !important;
  margin-top: 28px;
}
</style>
