<template>
  <!-- 添加数据集 -->
  <el-dialog
    title="新建数据集"
    :visible.sync="addDatasetDialogVisible"
    width="27.39583vw"
  >
    <el-form
      :model="form"
      :rules="rules"
      ref="ruleForm"
      label-position="left"
      label-width="7vw"
    >
      <el-form-item label="数据集名称：" prop="datasetName">
        <el-input
          v-model.trim="form.datasetName"
          placeholder="请输入数据集名称"
          maxlength="20"
          show-word-limit
        />
      </el-form-item>
      <el-form-item label="选择类型：">
        <el-select v-model="form.datasetType">
          <el-option
            v-for="option in options"
            :key="option.id"
            :label="option.name"
            :value="option.id"
          />
        </el-select>
      </el-form-item>
      <!-- 底部按钮 -->
      <el-form-item>
        <div class="form-btn-group">
          <Button
            class="form-btn cancel"
            @click="addDatasetDialogVisible = false"
            >取消</Button
          >
          <Button class="form-btn confirm" @click="handleConfirm('ruleForm')"
            >确定</Button
          >
        </div>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex';
import Button from 'c/basic/Button';
export default {
  name: 'AddDatasetDialog',
  components: {
    Button
  },
  data() {
    return {
      form: {
        datasetName: '',
        datasetType: '1'
      },

      options: [
        {
          id: '1',
          name: '要素数据'
        }
        // {
        //   id: '2',
        //   name: '格栅数据'
        // },
        // {
        //   id: '3',
        //   name: '图表数据'
        // },
        // {
        //   id: '4',
        //   name: '视频数据'
        // },
        // {
        //   id: '5',
        //   name: '倾斜影像模型数据'
        // },
        // {
        //   id: '6',
        //   name: '模型数据'
        // }
      ],
      rules: {
        datasetName: [
          { required: true, message: '请输入数据集名称', trigger: 'blur' }
        ]
      }
    };
  },
  computed: {
    addDatasetDialogVisible: {
      get() {
        return this.$store.state.data.addDatasetDialogVisible;
      },
      set(val) {
        this.$store.commit('data/setAddDatasetDialogVisible', val);
      }
    },
    ...mapState('common', ['workspaceId'])
  },
  methods: {
    // 提交
    handleConfirm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          const params = {
            name: this.form.datasetName,
            type: this.form.datasetType,
            workspaceId: this.workspaceId
          };
          this.$service.data.datasetAdd(params).then(data => {
            if (data.data.status === 200) {
              this.$message.success(data.data.msg);
              this.$emit('refresh');
              this.addDatasetDialogVisible = false;
            }
          });
        }
      });
    }
  }
};
</script>
