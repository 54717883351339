import $bus from 'p/utils';
import pointMark from 'a/map/point-mark.png';
import videoIcon from 'a/map/video-mark.png';
import { ip as urlIp } from 'p/urlSplice';

// 使用方法返回右键菜单，避免修改时相互影响
// 数据根目录右键菜单
const featureRootContextMenuData = () => [
  // {
  //   label: '复制',
  //   icon: 'copy',
  //   disabled: true
  // },
  // {
  //   label: '粘贴',
  //   icon: 'paste',
  //   disabled: true
  // },
  {
    label: '新建数据集',
    icon: 'add',
    fn(vm) {
      vm.$store.commit(
        'data/setAddDatasetId',
        vm.$store.state.common.contextClickData
      );
    }
  }
  // {
  //   label: '导入数据',
  //   icon: 'import',
  //   fn(vm) {
  //     const data = {
  //       status: true,
  //       data: vm.$store.state.common.contextClickData
  //     };
  //     vm.$store.commit('data/setImportDataFeatureClassDialogVisible', data);
  //   }
  // }
];

// 数据集右键菜单
const featureDatasetContextMenuData = () => [
  {
    label: '复制',
    icon: 'copy',
    fn(vm) {
      vm.$store.commit(
        'data/setDatasetCopyId',
        vm.$store.state.common.contextClickData
      );
    }
  },
  // {
  //   label: '粘贴',
  //   icon: 'paste',
  //   disabled: true
  // },
  {
    label: '重命名',
    icon: 'rename',
    fn(vm) {
      vm.$store.commit(
        'common/setEditTextId',
        vm.$store.state.common.contextClickData.id
      );
    }
  },
  {
    label: '删除',
    icon: 'del',
    fn(vm) {
      let data = vm.$store.state.common.contextClickData;
      let title = '';
      let requestType = '';
      switch (data.type) {
        // 要素集
        case 1:
          (title = '要素集'), (requestType = 'datasetDel');
          break;

        default:
          break;
      }
      vm.$confirm(
        '删除“' + data.name + '”后数据无法恢复，是否确认删除该' + title + '？',
        '删除' + title,
        {
          confirmButtonText: '删除',
          cancelButtonText: '取消',
          type: 'warning',
          customClass: 'del'
        }
      )
        .then(() => {
          const params = {
            id: data.id
          };
          vm.$service.data[requestType](params).then(res => {
            if (res.data.status === 200) {
              vm.$message.success(res.data.msg);
              // 刷新菜单列表数据
              $bus.$emit('refreshDS');
            }
          });
        })
        .catch(() => {});
    }
  },
  {
    label: '新建数据类',
    icon: 'add',

    fn(vm) {
      vm.$store.commit(
        'data/setAddDataclassDialogVisible',
        vm.$store.state.common.contextClickData
      );
    }
  },
  {
    type: 'splitLine'
  },
  {
    label: '属性',
    icon: 'attr',
    fn(vm) {
      vm.$store.commit(
        'data/setDatasetAttrDialogVisible',
        vm.$store.state.common.contextClickData
      );
    }
  }
];

// 要素数据数据类右键菜单
const featureDataclassContextMenuData = () => {
  let contextMenuData = [
    {
      label: '复制',
      icon: 'copy',
      disabled: true
    },
    // {
    //   label: '粘贴',
    //   icon: 'paste',
    //   disabled: true
    // },
    {
      label: '重命名',
      icon: 'rename',
      fn(vm) {
        vm.$store.commit(
          'common/setEditTextId',
          vm.$store.state.common.contextClickData.id
        );
      }
    },
    {
      label: '删除',
      icon: 'del',
      fn(vm) {
        let data = vm.$store.state.common.contextClickData;
        let title = '';
        let requestType = '';
        switch (data.type) {
          // 要素数据类
          case 1:
            (title = '要素数据类'), (requestType = 'featureClassDel');
            break;

          default:
            break;
        }
        vm.$confirm(
          '删除“' +
            data.name +
            '”后数据无法恢复，是否确认删除该' +
            title +
            '？',
          '删除' + title,
          {
            confirmButtonText: '删除',
            cancelButtonText: '取消',
            type: 'warning',
            customClass: 'del'
          }
        )
          .then(() => {
            const params = {
              id: data.id
            };
            vm.$service.data[requestType](params).then(res => {
              if (res.data.status === 200) {
                vm.$message.success(res.data.msg);
                // 当删除的数据类有打开属性表时 关闭属性表
                if (
                  data.id ==
                  vm.$store.state.data.dataclassAttrSheetPanelVisible.id
                ) {
                  vm.$store.commit(
                    'data/setDataclassAttrSheetPanelVisible',
                    false
                  );
                }
                // 刷新菜单列表数据
                $bus.$emit('partialRefresh');
              }
            });
          })
          .catch(() => {});
      }
    },
    {
      type: 'splitLine'
    },
    // {
    //   label: '导出数据',
    //   icon: 'export',
    //   fn(vm) {
    //     vm.$store.commit('data/setExportDataDialogVisible', true);
    //   }
    // },
    {
      label: '新建标绘',
      icon: 'add',
      fn(vm) {
        // 判断标绘要素的类型
        let { dataType, datasetId, id } =
          vm.$store.state.common.contextClickData;
        // 设置标绘数据集Id和数据类Id
        let plotType = '';
        switch (dataType) {
          // 1—点
          case 1:
            plotType = 'Point';
            break;
          // 2—多点
          case 2:
            break;
          // 3—线
          case 3:
            plotType = 'Polyline';
            break;
          // 4—多线
          case 4:
            break;
          // 5—多边形
          case 5:
            plotType = 'Polygon';
            break;
          // 6—多面
          case 6:
            break;
        }
        const data = {
          plotType,
          datasetId,
          dataclassId: id
        };
        $bus.$emit('plotFeature', data);
      }
    },
    {
      label: '展示数据',
      icon: 'showData',
      disabled: false,
      fn(vm, menuData) {
        // 正在加载
        if (menuData.label === '加载中...') return;
        const contextClickData = vm.$store.state.common.contextClickData;
        const dataClassFeaturesData =
          vm.$store.state.data.dataClassFeaturesData; // 数据类数据集合
        // 要素类型、要素类Id
        const { dataType, id: classId } = contextClickData;
        const featuresDataKey = classId;
        if (menuData.label == '展示数据' || menuData.label == '暂无数据') {
          // 修改加载数据为加载中...
          menuData.label = '加载中...';
          // 并禁用按钮
          menuData.disabled = true;

          // 加载对应数据
          // 判断是否存在数据
          let featuresDataObj = dataClassFeaturesData[featuresDataKey];
          // 数据备注

          let featuresDataRemark = featuresDataObj
            ? featuresDataObj.remark
            : '';
          // 判断数据是否更新了，更新了就去请求最新数据
          if (vm.$store.state.data.dataNeedUpdate.includes(classId)) {
            featuresDataObj = '';
          }
          if (featuresDataObj && featuresDataRemark != '未加载全部数据') {
            // 存在这从本地读取
            for (const key in featuresDataObj) {
              if (Object.hasOwnProperty.call(featuresDataObj, key)) {
                let featuresData = featuresDataObj[key];
                featuresData.forEach(featureData => {
                  featureData.show = true;
                });
                // 隐藏对应类标绘数据
                $bus.$emit('delPlotFeature', {
                  type: key,
                  classId,
                  show: true
                });
              }
            }
            // 更新菜单数据的展示状态
            $bus.$emit('refreshDataClassDataShowEdit');
            menuData.label = '隐藏数据';
            menuData.icon = 'hideData';
            menuData.disabled = false;
          } else {
            // 不存在则从后台读取
            vm.$set(dataClassFeaturesData, featuresDataKey, {});

            let req = '';
            let featureKey = '';
            switch (dataType) {
              case 1:
                req = 'pointLoad';
                featureKey = 'Point';
                break;
              case 3:
                req = 'lineLoad';
                featureKey = 'Polyline';
                break;
              case 5:
                req = 'polygonLoad';
                featureKey = 'Polygon';
                break;
            }
            vm.$service.feature[req]({
              classId
            }).then(res => {
              const { status, data } = res;
              if (status === 200) {
                // 修改右键菜单文字
                menuData.label = '隐藏数据';
                menuData.icon = 'hideData';
                menuData.disabled = false;
                // 禁用刷新按钮
                contextMenuData[4].disabled = false;

                let featuresData = [];
                // 遍历数据
                for (let i = 0; i < data.length; i++) {
                  const item = data[i];
                  let featureData = {};
                  switch (dataType) {
                    case 1:
                      {
                        const icon = item.iconUrl
                          ? urlIp.urlSplice(item.iconUrl)
                          : pointMark;
                        featureData = {
                          coordinates: [item.lng, item.lat, item.z],
                          icon,
                          show: true,
                          edit: false,
                          featureData: {
                            type: 'dataFeature',
                            featureType: 'Point',
                            uuid: item.id,
                            dialogData: {
                              classId,
                              id: item.id
                            }
                          }
                        };
                      }

                      break;
                    case 3:
                      {
                        let coordinates = item.line.map(coord => [
                          coord.x,
                          coord.y,
                          coord.z
                        ]);
                        let segmentColors = item.lineStyle
                          ? item.lineStyle.fillColor.map(color => {
                              const hex = color.slice(0, 7);
                              const opacity = color.slice(7, 9)
                                ? (
                                    (parseInt(color.slice(7, 9), 16) *
                                      (100 / 255)) /
                                    100
                                  ).toFixedNum(2)
                                : 1;
                              return [hex, opacity];
                            })
                          : [['#00A2E8', 1]];
                        featureData = {
                          coordinates,
                          segmentColors,
                          show: true,
                          edit: false,
                          change: e => {
                            const { type, index, point } = e;
                            let coordinate = [];
                            if (point) {
                              coordinate = [
                                point.longitude,
                                point.latitude,
                                point.altitude
                              ];
                            }
                            $bus.$emit('pointChange', {
                              type,
                              coordinate,
                              index
                            });
                            switch (type) {
                              case 'add':
                                featureData.coordinates.splice(
                                  index,
                                  0,
                                  coordinate
                                );
                                break;
                              case 'remove':
                                featureData.coordinates.splice(index, 1);
                                break;
                              case 'set':
                                featureData.coordinates[index] = coordinate;
                                $bus.$emit('pointChange', {
                                  coordinate,
                                  index
                                });
                                break;
                            }
                          },
                          featureData: {
                            type: 'dataFeature',
                            featureType: 'Polyline',
                            uuid: item.id,
                            dialogData: {
                              classId,
                              id: item.id
                            }
                          }
                        };
                      }
                      break;
                    case 5:
                      {
                        let polygon = item.polygon;
                        // 删除最后一个首尾相连的点
                        polygon.pop();
                        let coordinates = polygon.map(coord => [
                          coord.x,
                          coord.y,
                          coord.z
                        ]);
                        let fillColor = item.polygonStyle
                          ? item.polygonStyle.fillColor
                          : '#00A2E8B3';
                        const hex = fillColor.slice(0, 7);
                        const opacity = fillColor.slice(7, 9)
                          ? (
                              (parseInt(fillColor.slice(7, 9), 16) *
                                (100 / 255)) /
                              100
                            ).toFixedNum(2)
                          : 1;
                        featureData = {
                          coordinates,
                          fillColor: [hex, opacity],
                          show: true,
                          edit: false,
                          change: e => {
                            const { type, index, point } = e;
                            let coordinate = [];
                            if (point) {
                              coordinate = [
                                point.longitude,
                                point.latitude,
                                point.altitude
                              ];
                            }
                            $bus.$emit('pointChange', {
                              type,
                              coordinate,
                              index
                            });
                            switch (type) {
                              case 'add':
                                featureData.coordinates.splice(
                                  index,
                                  0,
                                  coordinate
                                );
                                break;
                              case 'remove':
                                featureData.coordinates.splice(index, 1);
                                break;
                              case 'set':
                                featureData.coordinates[index] = coordinate;
                                $bus.$emit('pointChange', {
                                  coordinate,
                                  index
                                });
                                break;
                            }
                          },
                          featureData: {
                            type: 'dataFeature',
                            featureType: 'Polygon',
                            uuid: item.id,
                            dialogData: {
                              classId,
                              id: item.id
                            }
                          }
                        };
                      }
                      break;
                  }
                  featuresData.push(featureData);
                }
                let dataClassFeatureData = {};
                dataClassFeatureData[featureKey] = featuresData;
                // 更新数据到地图
                vm.$store.commit('data/setDataClassFeaturesData', {
                  key: featuresDataKey,
                  val: dataClassFeatureData
                });
                // 取消更新状态
                let dataNeedUpdate = vm.$store.state.data.dataNeedUpdate;
                dataNeedUpdate.splice(dataNeedUpdate.indexOf(classId), 1);
                vm.$store.commit('data/setDataNeedUpdate', dataNeedUpdate);

                // 更新菜单数据的展示状态
                $bus.$emit('refreshDataClassDataShowEdit');
                // 隐藏对应类标绘数据
                $bus.$emit('delPlotFeature', {
                  type: featureKey,
                  classId,
                  show: true
                });
              }
            });
          }
        } else {
          // 修改隐藏数据为加载数据
          menuData.label = '展示数据';
          menuData.icon = 'showData';
          menuData.disabled = false;
          // 隐藏数据
          let featuresDataObj = dataClassFeaturesData[featuresDataKey];
          if (featuresDataObj) {
            for (const key in featuresDataObj) {
              if (
                Object.hasOwnProperty.call(featuresDataObj, key) &&
                key !== 'remark'
              ) {
                const featuresData = featuresDataObj[key];
                // 隐藏数据
                featuresData.map(featureData => (featureData.show = false));

                // 隐藏对应类标绘数据
                $bus.$emit('delPlotFeature', {
                  type: key,
                  classId,
                  show: false
                });
              }
            }
          }
          // 更新菜单数据的展示状态
          $bus.$emit('refreshDataClassDataShowEdit');
        }
      }
    },
    {
      label: '打开属性表',
      icon: 'attrSheet',
      fn(vm) {
        // 关闭视频属性表
        // vm.$store.commit('data/setVideoPropertySheetVisible', false);
        // 关闭要素类属性表
        // vm.$store.commit('data/setDataclassAttrSheetPanelVisible', false);
        vm.$nextTick(() => {
          vm.$store.commit('data/setDataclassAttrSheetPanelVisible', true);
          const contextClickData = vm.$store.state.common.contextClickData;
          let data = Object.assign(
            {
              propertySheetType: 'data',
              status: true
            },
            contextClickData
          );
          vm.$store.commit('data/setDataclassAttrSheetPanelVisible', data);
        });
      }
    },
    {
      label: '属性',
      icon: 'attr',
      fn(vm) {
        const data = {
          status: true,
          id: vm.$store.state.common.contextClickData.id
        };
        vm.$store.commit('data/setDataclassAttrDialogVisible', data);
      }
    }
  ];
  return contextMenuData;
};

// 栅格数据集右键菜单
const gridDatasetContextMenuData = () => [
  {
    label: '复制',
    icon: 'copy',
    disabled: true
  },
  {
    label: '粘贴',
    icon: 'paste',
    disabled: true
  },
  {
    label: '重命名',
    icon: 'rename',
    fn(vm) {
      vm.$store.commit(
        'common/setEditTextId',
        vm.$store.state.common.contextClickData.id
      );
    }
  },
  {
    label: '删除',
    icon: 'del',
    fn(vm) {
      vm.$store.commit(
        'common/setDelDataId',
        vm.$store.state.common.contextClickData
      );
    }
  },
  {
    type: 'splitLine'
  },
  {
    label: '新建数据',
    icon: 'add',
    rightIcon: 'right',
    childShow: false,
    children: [
      {
        label: '添加在线服务',
        fn(vm) {
          vm.$store.commit('data/setOnlineServiceDialogVisible', true);
        }
      },
      {
        label: '添加本地数据',
        fn(vm) {
          console.log(vm);
        }
      }
    ]
  },
  {
    label: '属性',
    icon: 'attr',
    fn(vm) {
      vm.$store.commit('data/setGridDataclassAttrDialogVisible', true);
    }
  }
];

// 栅格数据数据类右键菜单
const gridDataclassContextMenuData = () => {
  let contextMenuData = [
    {
      label: '复制',
      icon: 'copy',
      disabled: true
    },
    {
      label: '粘贴',
      icon: 'paste',
      disabled: true
    },
    {
      label: '重命名',
      icon: 'rename',
      fn(vm) {
        vm.$store.commit(
          'common/setEditTextId',
          vm.$store.state.common.contextClickData.id
        );
      }
    },
    {
      label: '删除',
      icon: 'del',
      fn(vm) {
        vm.$store.commit('data/setRemoveDataDialogVisible', true);
      }
    },
    {
      type: 'splitLine'
    },
    {
      label: '展示数据',
      icon: 'showData',
      disabled: false,
      fn(vm) {
        console.log(vm);
      }
    },
    {
      label: '属性',
      icon: 'attr',
      fn(vm) {
        vm.$store.commit('data/setGridDataclassAttrDialogVisible', true);
      }
    }
  ];
  return contextMenuData;
};

// 视频数据数据类右键菜单
const videoDataclassContextMenuData = () => [
  {
    label: '复制',
    icon: 'copy',
    disabled: true
  },
  {
    label: '粘贴',
    icon: 'unpaste',
    disabled: true
  },
  {
    label: '重命名',
    icon: 'layerAttrEdit',
    fn(vm) {
      vm.$store.commit('setEditDataId', vm.$store.state.common.selectedDataId);
    }
  },
  {
    label: '删除',
    icon: 'delete',
    fn(vm) {
      vm.$store.commit('setRemoveDataTypeVisible', true);
    }
  },
  {
    type: 'splitLine'
  },
  {
    label: '添加视频数据',
    icon: 'add',
    fn(vm) {
      vm.$store.commit('setAddVideoVisible', true);
    }
  },
  {
    type: 'splitLine'
  },
  {
    label: '加载数据',
    icon: 'load',
    fn(vm, menuData) {
      if (menuData.label === '加载中...') return;
      const contextClickData = vm.$store.state.common.contextClickData;
      const id = contextClickData.id; // 要素类Id
      const videoData = vm.$store.state.data.dataClassVideoData;
      if (menuData.label === '加载数据') {
        // 修改加载数据为加载中...
        menuData.label = '加载中...';
        // 判断数据是否缓存
        if (videoData[id]) {
          videoData[id].forEach(item => {
            item.show = true;
          });
          menuData.label = '隐藏数据';
          menuData.icon = 'hideData';
        } else {
          vm.$req.data.getVideoData({
            params: {
              classId: id
            },
            success(data) {
              if (data.status === 200) {
                menuData.label = '隐藏数据';
                menuData.icon = 'hideData';
                const res = data.data.map(item => {
                  return {
                    coordinates: [item.lon, item.lat],
                    icon: videoIcon,
                    show: true,
                    featureData: item
                  };
                });
                const videoData = vm.$store.state.data.dataClassVideoData;
                vm.$set(videoData, id, res);
              }
            }
          });
        }
      } else {
        // 修改菜单文本
        menuData.label = '加载数据';
        menuData.icon = 'load';
        videoData[id].forEach(item => {
          item.show = false;
        });
      }
    }
  },
  {
    type: 'splitLine'
  },
  {
    label: '打开属性表',
    icon: 'attrTable',
    fn(vm) {
      // 关闭要素属性表
      vm.$store.commit('setPropertySheetVisible', false);
      // 关闭视频属性表
      vm.$store.commit('setVideoPropertySheetVisible', false);
      vm.$nextTick(() => {
        vm.$store.commit('setVideoPropertySheetVisible', true);
        const contextClickData = vm.$store.state.common.contextClickData;
        let data = Object.assign({}, contextClickData);
        vm.$store.commit('setPropertySheetData', data);
      });
    }
  },
  {
    type: 'splitLine'
  },
  {
    label: '属性',
    icon: 'attr',
    disabled: true
  }
];

export {
  videoDataclassContextMenuData,
  gridDataclassContextMenuData,
  gridDatasetContextMenuData,
  featureDataclassContextMenuData,
  featureDatasetContextMenuData,
  featureRootContextMenuData
};
