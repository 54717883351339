<template>
  <div>
    <Panel title="数据管理" @close="handleClose" v-loading="!workspaceId">
      <div class="dataManager">
        <div class="dataManager-header">
          <div class="dataManager-header-top">
            <!-- 左侧工作空间 -->
            <WorkSpaceSelect :value="workspaceId" />
            <!-- 右侧 -->
            <div class="dataManager-header-btn-group">
              <!-- 导入数据 -->
              <!-- <list-tooltip content="导入数据">
                <button
                  class="dataManager-header-btn"
                  @click="datasetImportDataDialogVisible = true"
                >
                  <i class="icon icon-dataImport"></i>
                </button>
              </list-tooltip> -->
              <!-- 添加数据 -->
              <list-tooltip content="添加数据集">
                <button class="dataManager-header-btn" @click="addData">
                  <i class="icon icon-dataAdd"></i>
                </button>
              </list-tooltip>
              <!-- toggle按钮 -->
              <list-tooltip :content="!toggle ? '收起目录' : '展开已加载数据'">
                <button class="dataManager-header-btn" @click="toggleTree">
                  <i
                    :class="[
                      'icon',
                      !toggle ? 'icon-tree-toggleUp' : 'icon-tree-toggleDown '
                    ]"
                  ></i>
                </button>
              </list-tooltip>
            </div>
          </div>
          <!-- 搜索框 -->
          <!-- <div class="dataManager-header-serach">
            <SearchBox v-model="keyword" @search="handleSearch" />
          </div> -->
        </div>
        <div class="dataManager-content">
          <!-- 树状图 -->
          <el-tree
            ref="tree"
            :props="props"
            :load="loadNode"
            :data="treeData"
            lazy
            node-key="id"
            draggable
            @node-drop="handleDrop"
            :allow-drag="handleAllowDrag"
            :allow-drop="handleAllowDrop"
          >
            <!-- :default-expanded-keys="treeExpandelKeys" -->
            <div
              :class="'custom-tree-node'"
              slot-scope="{ node, data }"
              @contextmenu="handleContextmenu($event, data, node)"
            >
              <!-- 根目录 -->
              <template v-if="!data.name">
                <!-- 左侧箭头 -->
                <div class="custom-tree-node-toggle">
                  <i
                    class="iconfont icon-arrow-right-filling custom-tree-node-toggle-icon"
                    :class="node.expanded ? 'expanded' : ''"
                  ></i>
                </div>
                <div class="custom-tree-node-text">
                  <!-- 名称 -->
                  <span class="custom-tree-node-name">{{ data.typeName }}</span>
                  <!-- 个数 -->
                  <span v-if="data" class="custom-tree-node-num"
                    >（{{ data.datasets.length }}）</span
                  >
                </div>
              </template>
              <!-- 数据集 -->
              <template v-if="data.id && !data.datasetId && data.name">
                <!-- 左侧箭头 -->
                <div class="custom-tree-node-toggle">
                  <i
                    class="iconfont icon-arrow-right-filling custom-tree-node-toggle-icon"
                    :class="node.expanded ? 'expanded' : ''"
                    v-show="!node.isLeaf"
                  ></i>
                </div>
                <div class="custom-tree-node-text">
                  <!-- 数据集可编辑 -->
                  <EditText
                    v-if="data.id == editTextId"
                    class="editText"
                    :value="data.name"
                    :id="data.id"
                    :maxlength="20"
                    @edit="text => handleDatasetNameEdit(text, data, node)"
                    @del="text => handleDatasetNameDel(text, data, node)"
                  >
                  </EditText>
                  <template v-else>
                    <!-- 名称 -->
                    <el-tooltip
                      :content="data.name"
                      :disabled="data.name.length < 11"
                      placement="bottom"
                      :enterable="false"
                      transition="none"
                      :visible-arrow="false"
                      popper-class="atooltip1"
                    >
                      <span>{{ data.name }}</span>
                    </el-tooltip>
                  </template>
                </div>
                <!-- 右侧按钮 -->
                <div class="custom-tree-node-right">
                  <i
                    class="icon icon-setting"
                    v-show="data.id !== editTextId"
                    @click.stop="handleDatasetProperty(data)"
                  ></i>
                </div>
              </template>
              <!-- 数据类 -->
              <template v-if="data.datasetId">
                <!-- 左侧图标 -->
                <div class="custom-tree-node-toggle">
                  <i :class="['icon', 'icon-' + data.dataType]"></i>
                </div>
                <div class="custom-tree-node-text">
                  <EditText
                    v-if="data.id == editTextId"
                    class="editText"
                    :value="data.name"
                    :id="data.id"
                    :maxlength="20"
                    @edit="text => handleDatasetNameEdit(text, data, node)"
                    @del="text => handleDatasetNameDel(text, data, node)"
                  >
                  </EditText>
                  <template v-else>
                    <!-- 名称 -->
                    <el-tooltip
                      :content="data.name"
                      :disabled="data.name.length < 8"
                      placement="bottom"
                      :enterable="false"
                      transition="none"
                      :visible-arrow="false"
                      popper-class="atooltip1"
                    >
                      <span class="custom-tree-node-name">{{ data.name }}</span>
                    </el-tooltip>
                    <!-- 个数 -->
                    <span v-if="data.parentType" class="custom-tree-node-num"
                      >（{{ data.num }}）</span
                    >
                  </template>
                </div>

                <!-- 右侧按钮 -->
                <div
                  class="custom-tree-node-right"
                  v-if="data.id !== editTextId"
                >
                  <i
                    v-if="data.parentType !== 2"
                    @click.stop="handleCategoryShowData(data, node)"
                    :class="
                      data.showData
                        ? 'icon icon-check-close'
                        : 'icon icon-check'
                    "
                  ></i>
                  <i
                    v-if="data.parentType !== 2"
                    class="icon icon-attrSheet"
                    @click.stop="handleCategoryAttributeTable(data)"
                  ></i>
                  <i
                    class="icon icon-setting"
                    @click.stop="handleCategoryProperty(data, node)"
                  ></i>
                </div>
              </template>
            </div>
          </el-tree>
        </div>
      </div>
    </Panel>
    <!-- 导入数据 -->
    <DatasetImportDataDialog v-if="datasetImportDataDialogVisible" />
    <!-- 添加数据集 -->
    <AddDatasetDialog
      v-if="addDatasetDialogVisible"
      @refresh="getGroupData(workspaceId)"
    />
    <!-- 添加数据类 -->
    <AddDataclassDialog
      v-if="addDataclassDialogVisible"
      @partialRefresh="partialRefresh"
    />
    <!-- 数据集属性 -->
    <DatasetAttrDialog
      v-if="datasetAttrDialogVisible"
      @refresh="getGroupData(workspaceId)"
    />
    <!-- 数据类属性 -->
    <DataclassAttrDialog @partialRefresh="partialRefresh" />
    <!-- 要素类属性样式编辑窗口 -->
    <DataclassAttrSytleEditDialog
      @partialRefresh="partialRefresh"
      v-if="dataclassAttrDialogVisible.status"
    />
    <!-- 数据类属性表 -->
    <DataclassAttrSheetDialog v-if="dataclassAttrSheetPanelVisible.status" />
    <!-- 数据类属性字段 -->
    <DataclassAttrFieldDialog v-if="dataclassAttrFieldDialogVisible" />
    <!-- 添加在线服务 -->
    <AddOnlineServiceDialog v-if="onlineServiceDialogVisible" />
    <!-- 栅格数据属性表 -->
    <GridDataclassAttrDialog v-if="gridDataclassAttrDialogVisible" />
    <ImportDataFeatureClassDialog v-if="importDataFeatureClassDialogVisible" />
  </div>
</template>

<script>
import $bus from 'p/utils';
import { mapState } from 'vuex';
import Panel from 'c/basic/Panel';
import EditText from 'c/basic/EditText';
// import SearchBox from 'c/basic/SearchBox';
import AddDatasetDialog from './AddDatasetDialog';
import AddDataclassDialog from './AddDataclassDialog';
import DatasetAttrDialog from './DatasetAttrDialog';
import DataclassAttrDialog from './DataclassAttrDialog';
import DataclassAttrSytleEditDialog from './DataclassAttrSytleEditDialog';
import DataclassAttrSheetDialog from './DataclassAttrSheetDialog';
import DataclassAttrFieldDialog from './DataclassAttrFieldDialog';
import DatasetImportDataDialog from './DatasetImportDataDialog';
import AddOnlineServiceDialog from './AddOnlineServiceDialog';
import GridDataclassAttrDialog from './GridDataclassAttrDialog';
import WorkSpaceSelect from 'c/workbench/WorkSpaceSelect';

// 导入要素数据类数据窗口
import ImportDataFeatureClassDialog from './ImportDataFeatureClassDialog';
// 引入数据右键菜单数据
import {
  featureRootContextMenuData,
  featureDatasetContextMenuData,
  featureDataclassContextMenuData,
  gridDatasetContextMenuData,
  gridDataclassContextMenuData
} from './dataContextMenuData';
import ListTooltip from 'c/basic/ListTooltip.vue';
export default {
  name: 'DataManager',
  components: {
    GridDataclassAttrDialog,
    AddOnlineServiceDialog,
    WorkSpaceSelect,
    DatasetImportDataDialog,
    DataclassAttrFieldDialog,
    DataclassAttrSheetDialog,
    DataclassAttrDialog,
    DatasetAttrDialog,
    AddDataclassDialog,
    AddDatasetDialog,
    ImportDataFeatureClassDialog,
    // SearchBox,
    DataclassAttrSytleEditDialog,
    EditText,
    Panel,
    ListTooltip
  },
  data() {
    return {
      featureRootContextMenuData: featureRootContextMenuData(),
      featureDatasetContextMenuData: featureDatasetContextMenuData(),
      featureDataclassContextMenuData: featureDataclassContextMenuData(),
      gridDatasetContextMenuData: gridDatasetContextMenuData(),
      gridDataclassContextMenuData: gridDataclassContextMenuData(),
      // loading显示隐藏状态
      loading: false,
      // 是否请求数据类数据
      reqDataclassState: true,
      // 搜索关键字
      keyword: '',
      // 树状结构数据
      treeData: [
        { id: 1, type: 1, typeName: '要素数据目录', datasets: [] }
        // { id: 2, type: 2, typeName: '栅格数据目录', datasets: [] },
        // { id: 3, type: 3, typeName: '图表数据目录', datasets: [] },
        // { id: 4, type: 4, typeName: '视频数据目录', datasets: [] },
        // { id: 5, type: 5, typeName: '倾斜摄影模型数据', datasets: [] },
        // { id: 6, type: 6, typeName: '模型数据', datasets: [] }
      ],
      initialTreeData: [
        { id: 1, type: 1, typeName: '要素数据目录', datasets: [] }
        // { id: 2, type: 2, typeName: '栅格数据目录', datasets: [] },
        // { id: 3, type: 3, typeName: '图表数据目录', datasets: [] },
        // { id: 4, type: 4, typeName: '视频数据目录', datasets: [] },
        // { id: 5, type: 5, typeName: '倾斜摄影模型数据', datasets: [] },
        // { id: 6, type: 6, typeName: '模型数据', datasets: [] }
      ],
      // 树状图配置
      props: {
        label: 'name',
        value: 'id',
        isLeaf: 'leaf',
        type: 'type',
        children: 'datasets'
      },
      // 树状天收起展开状态
      toggle: false,
      // 树形状态
      inventoryStatus: [],
      // 提示文本
      isTooltip: true,
      // 存放复制超过限制字符的数据集id
      copyId: '',
      rootNode: [], //保存树形所有节点数据
      dataClassNode: {},
      fristNodeKeys: [],
      currentNode: null,
      dropNodeId: '',
      dropNodeStatus: true
    };
  },
  computed: {
    ...mapState('data', [
      'gridDataclassAttrDialogVisible',
      'onlineServiceDialogVisible',
      'addDatasetDialogVisible',
      'addDataclassDialogVisible',
      'datasetAttrDialogVisible',
      'dataclassAttrDialogVisible',
      'dataclassAttrSheetPanelVisible',
      'dataclassAttrFieldDialogVisible',
      'importDataFeatureClassDialogVisible',
      'addDatasetId',
      'datasetCopyId',
      'dataClassFeaturesData',
      'datasetImportDataDialogVisible'
    ]),
    ...mapState('common', ['workspaceId', 'delData', 'editTextId'])
  },
  mounted() {
    var that = this;
    if (that.workspaceId) {
      that.getGroupData(that.workspaceId);
    }
    $bus.$on('refreshDS', () => {
      that.getGroupData(that.workspaceId);
    });
    $bus.$on('partialRefresh', () => {
      that.partialRefresh();
    });
    $bus.$on('refreshDataClassDataShowEdit', () => {
      this.dataClassDataShowEdit();
    });
  },
  destroyed() {
    $bus.$emit('quitPlot');
    $bus.$off('refreshDS');
    $bus.$off('refreshDataClassDataShowEdit');
    $bus.$off('partialRefresh');
    this.$store.commit('data/quitDataManager');
    this.$store.commit('data/setDataclassAttrSheetPanelVisible', false);
    this.$store.commit('common/setContextMenuVisible', false);
  },
  methods: {
    // 判断是否可以进行拖拉移动
    handleAllowDrag(node) {
      if (!node.data.typeName) return true;
    },
    // 拖拉移动放置判断
    handleAllowDrop(draggingNode, dropNode, type) {
      if (
        type == 'inner' &&
        dropNode.data.name &&
        !dropNode.data.datasetId &&
        draggingNode.data.type == dropNode.data.type
      ) {
        if (dropNode.data.id != this.dropNodeId) {
          this.dropNodeId = dropNode.data.id;
          this.$service.data
            .featureClassGetByDataset({ datasetId: dropNode.data.id })
            .then(res => {
              if (res.data.status === 200) {
                let num = 0;
                res.data.data.map(item => {
                  num++;
                  if (item.name == draggingNode.data.name) {
                    num--;
                  }
                });
                if (num == res.data.data.length) {
                  this.dropNodeStatus = true;
                } else {
                  this.dropNodeStatus = false;
                  this.dropNodeId = '';
                }
              }
            });
        }
        return this.dropNodeStatus;
      }
    },
    // 拖拉移动结束-提交请求
    handleDrop(Node, parenNode) {
      const params = {
        id: Node.data.id,
        datasetId: parenNode.data.id
      };
      this.$service.data.featureClassMove(params).then(res => {
        if (res.data.status === 200) {
          this.$message.success(res.data.msg);
        }
      });
    },
    // 树形懒加载
    loadNode(node, resolve) {
      if (node.level > 2) return resolve([]);
      // 初始化 加载根目录数据
      if (node.level === 0) return this.loadFirstNode(node, resolve);

      // 展开第一级节点 加载数据集
      if (node.level === 1) return this.loadSecondNode(node, resolve);

      // 展开第二级节点 加载数据类
      if (node.level === 2) return this.loadThirdlyNode(node, resolve);
    },
    // 加载目录
    loadFirstNode(node, resolve) {
      resolve(this.initialTreeData);
    },
    // 加载数据集
    loadSecondNode(node, resolve) {
      resolve(this.treeData[node.data.type - 1].datasets);
    },
    // 加载数据类
    loadThirdlyNode(node, resolve) {
      // 编辑时当前行不能点击加载
      if (this.editTextId == node.data.id) return;
      this.dataClassNode = node;
      const params = {
        datasetId: node.data.id
      };
      this.$service.data.featureClassGetByDataset(params).then(res => {
        if (res.data.status === 200) {
          res.data.data.map(item => {
            item.showData = false;
            item.type = 1;
          });
          resolve(res.data.data);
        }
      });
    },
    // 局部刷新方法
    partialRefresh() {
      const node = this.currentNode;
      // 新增或修改时,更新当前节
      node.loaded = false;
      node.loadData(); // 重新查询当前节点的所有子元素
      node.expanded = true;
    },
    // 刷新前保存树形结构展开状态
    stateOfTheTree() {
      this.inventoryStatus = [];
      if (this.$refs.tree.root) {
        this.$refs.tree.root.childNodes.forEach(item => {
          this.inventoryStatus.push(item.expanded);
        });
      }
    },
    // 刷新后恢复树形结构展开状态
    restoreTheTreeState() {
      this.inventoryStatus.forEach((item, index) => {
        if (this.$refs.tree.root) {
          this.$refs.tree.root.childNodes[index].expanded = item;
        }
      });
    },
    // 获取树形结构数据
    getGroupData(val) {
      this.stateOfTheTree();
      const params = {
        workspaceId: val
      };
      this.$service.data.datasetGetGroup(params).then(data => {
        if (data.data.status == 200) {
          this.$store.commit('common/setEditTextId', '');
          let arr = this.initialTreeData;
          // 给目录赋初始值
          arr.forEach(item => {
            let obj = JSON.parse(JSON.stringify(item));
            this.treeData = this.treeData.map(item1 =>
              item1.type === obj.type ? obj : item1
            );
          });
          // 后端数据赋值
          data.data.data.forEach(item => {
            let obj = item;
            obj.datasets[0].datasets = [];
            this.treeData = this.treeData.map(item1 =>
              item1.type === obj.type ? obj : item1
            );
          });

          // 恢复树形结构展开状态
          this.$nextTick(() => {
            this.restoreTheTreeState();
          });
        }
      });
    },
    // 数据集文本编辑
    handleDatasetNameEdit(text, data, node) {
      if (!text) return this.$message('名称不能为空');
      let params = {};
      let requestType = ''; //请求接口
      if (data.id < 1000000000 && data.id > 100000) {
        // 新增数据集
        requestType = 'datasetAdd';
        params = {
          name: text,
          type: data.type,
          workspaceId: this.workspaceId
        };
      } else if (data.id < 100000) {
        // 复制数据集
        requestType = 'datasetCopy';
        params = {
          id: this.copyId,
          newName: text,
          workspaceId: this.workspaceId
        };
      } else if (data.id > 1000000000 && !data.datasetId) {
        // 重命名数据集
        requestType = 'datasetModifyName';
        params = {
          id: data.id,
          name: text
        };
      } else if (data.datasetId) {
        // 重命名数据类
        requestType = 'featureClassRename';
        params = {
          id: data.id,
          name: text
        };
      }
      this.$service.data[requestType](params).then(res => {
        if (res.data.status == 200) {
          this.$message.success(res.data.msg);
          this.$store.commit('common/setEditTextId', '');
          this.$store.commit('data/setAddDatasetId', '');
          if (this.copyId) {
            this.copyId = '';
            this.getGroupData(this.workspaceId);
          } else {
            node.data.name = text;
            // 新增数据集
            if (requestType === 'datasetAdd') {
              node.data.id = res.data.data.id;
            }
          }
        }
      });
    },
    // 数据集属性快捷按钮
    handleDatasetProperty(data) {
      switch (data.type) {
        // 要素数据集
        case 1:
          this.$store.commit('data/setDatasetAttrDialogVisible', data);
          break;
        // 栅格数据集
        case 2:
          this.$store.commit('data/setGridDataclassAttrDialogVisible', data);
          break;
        default:
          break;
      }
    },
    // 要素数据类查看数据快捷按钮
    handleCategoryShowData(data) {
      this.$store.commit('common/setContextClickData', data);
      if (this.featureDataclassContextMenuData[5].label != '加载中...') {
        const { icon, label, disabled } = this.dataClassFeaturesDataShow(data);
        this.featureDataclassContextMenuData[5].icon = icon;
        this.featureDataclassContextMenuData[5].label = label;
        this.featureDataclassContextMenuData[5].disabled = disabled;
      }
      const item = this.featureDataclassContextMenuData[5];
      item.fn && item.fn(this, item);
    },
    // 要素数据类属性表快捷按钮
    handleCategoryAttributeTable(data) {
      this.$store.commit('common/setContextClickData', data);
      const item = this.featureDataclassContextMenuData[6];
      item.fn && item.fn(this, item);
    },
    // 要素数据类属性快捷按钮
    handleCategoryProperty(data, node) {
      this.currentNode = node.parent;
      this.$store.commit('common/setContextClickData', data);
      const item = this.featureDataclassContextMenuData[7];
      item.fn && item.fn(this, item);
    },
    // 右键
    handleContextmenu(e, data, node) {
      // 内容输入框编辑时禁止右键操作
      if (data.id == this.editTextId) return;
      if (data.datasetId) {
        this.currentNode = node.parent;
      } else {
        this.currentNode = node;
      }
      this.$store.commit('common/setContextClickData', data);
      // parentType 数据类型 1-要素 2-栅格 3-图表 4-视频
      let folderType = '';
      const parentType = data.type;
      if (!data.name) {
        folderType = 'root';
      } else if (data.id && !data.datasetId && data.name) {
        folderType = 'dataset';
      } else {
        folderType = 'dataclass';
      }
      // 更具目录类型设置右键菜单
      let contextMenuData = '';
      switch (folderType) {
        case 'root':
          contextMenuData = this.featureRootContextMenuData;
          break;
        case 'dataset':
          switch (parentType) {
            case 2:
              contextMenuData = this.gridDatasetContextMenuData;
              break;
            default:
              contextMenuData = this.featureDatasetContextMenuData;
              break;
          }
          break;
        case 'dataclass':
          // 判断是要数据类型
          // 如果是栅格移除标绘、打开属性表选项
          switch (parentType) {
            case 2:
              contextMenuData = this.gridDataclassContextMenuData;
              break;
            default:
              if (
                this.featureDataclassContextMenuData[5].label != '加载中...'
              ) {
                const { icon, label, disabled } =
                  this.dataClassFeaturesDataShow(data);
                this.featureDataclassContextMenuData[5].icon = icon;
                this.featureDataclassContextMenuData[5].label = label;
                this.featureDataclassContextMenuData[5].disabled = disabled;
              }
              contextMenuData = this.featureDataclassContextMenuData;
              break;
          }
          break;
      }
      // 设置选中标题
      // this.selectedDataId = this.getId(data);
      // 设置菜单数据
      this.$store.commit('common/setContextMenuData', contextMenuData);
      // 设置菜单可见状态
      this.$store.commit('common/setContextMenuVisible', true);
      // 设置菜单位置
      this.$store.commit('common/setContextMenuPosition', {
        top: e.pageY,
        left: e.pageX
      });
    },
    // 判断要素类数据是否展示
    dataClassFeaturesDataShow(data) {
      // 要素类数据的展示状态
      let showFeatures = '';
      let contextMenuData = {};
      if (this.dataClassFeaturesData[data.id]) {
        for (const key in this.dataClassFeaturesData[data.id]) {
          if (key == 'remark') {
            showFeatures = false;
          } else {
            // 空数据类
            if (this.dataClassFeaturesData[data.id][key].length == 0) {
              showFeatures = 'Empty';
            } else {
              // 遍历显示状态
              showFeatures = true;
              this.dataClassFeaturesData[data.id][key].map(item => {
                if (item.show === false) {
                  showFeatures = false;
                }
              });
            }
          }
        }
      }
      if (showFeatures == true) {
        contextMenuData.icon = 'hideData';
        contextMenuData.label = '隐藏数据';
        contextMenuData.disabled = false;
      } else if (showFeatures == 'Empty') {
        contextMenuData.icon = 'showData';
        contextMenuData.label = '暂无数据';
        contextMenuData.disabled = true;
      } else {
        contextMenuData.icon = 'showData';
        contextMenuData.label = '展示数据';
        contextMenuData.disabled = false;
      }
      return contextMenuData;
    },
    // 遍历更改要素数据类展示状态
    dataClassDataShowEdit() {
      if (!this.dataClassNode.childNodes) return;
      let node = this.dataClassNode;
      let dataClassFeaturesKey = [];
      node.parent.childNodes.map(i => {
        i.childNodes.map(j => {
          if (this.dataClassFeaturesData[j.data.id]) {
            for (const key in this.dataClassFeaturesData[j.data.id]) {
              if (key == 'remark') {
                j.data.showData = false;
              } else {
                this.dataClassFeaturesData[j.data.id][key].map(item => {
                  j.data.showData = item.show ? true : false;
                });
                // 获取展示数据的数据类Id
                if (j.data.showData == true) {
                  dataClassFeaturesKey.push(j.data.id);
                }
              }
            }
          } else {
            j.data.showData = false;
          }
        });
      });
      // 获取展示数据的数据类Id集合
      this.$store.commit('data/setDataClassFeaturesKeys', dataClassFeaturesKey);
    },
    // 展开收起树状图
    toggleTree() {
      this.$store.commit('common/setEditTextId', '');
      // this.toggle = !this.toggle;
      this.toggle = false;
      let nodes = this.$refs.tree.root.childNodes;
      const expandedState = this.toggle;
      changeNodeExpandedState(nodes);
      // 改变节点展开状态
      function changeNodeExpandedState(nodes) {
        for (let i = 0; i < nodes.length; i++) {
          let node = nodes[i];
          node.expanded = expandedState;
          // 判断子节点长度
          if (node.childNodes.length > 0) {
            changeNodeExpandedState(node.childNodes);
          }
        }
      }
    },
    // 关闭
    handleClose() {
      this.$router.push('/workbench/map');
    },
    // 关闭编辑数据集输入框
    handleDatasetNameDel(text, data, node) {
      if (data.id) {
        // 判断是新增还是编辑
        // 编辑
        if (data.id.length > 10) {
          // 还原名称
          node.data.name = text;
        } else {
          // 新增
          // 关闭时直接移除节点
          node.remove();
        }
      }
      this.$store.commit('data/setAddDatasetId', '');
    },
    // 新增数据集窗口
    addData() {
      this.$store.commit('data/setAddDatasetDialogVisible', true);
    }
  },
  watch: {
    // 切换工作空间
    workspaceId() {
      this.inventoryStatus = [];
      this.$store.commit('data/quitDataManager');
      this.restoreTheTreeState();
      this.getGroupData(this.workspaceId);
    },
    // 监听要素类是否有地图展示数据，如够更新菜单栏数据显示状态
    dataClassFeaturesData(data) {
      if (data) {
        this.dataClassDataShowEdit();
      }
    },
    // 添加数据集-右击添加
    addDatasetId(data) {
      if (data) {
        if (this.editTextId) return;
        this.$refs.tree.root.childNodes[data.type - 1].expanded = true;
        let name = '';
        let num = 1;
        switch (data.type) {
          case 1:
            name = '要素数据集';
            break;
          case 2:
            name = '格栅数据集';
            break;
          case 3:
            name = '图表数据集';
            break;
          case 4:
            name = '视频数据集';
            break;
          case 5:
            name = '倾斜摄影模型数据集';
            break;
          case 6:
            name = '模型数据集';
            break;
          default:
            break;
        }
        let str = JSON.stringify(this.treeData[data.type - 1]);
        if (str.includes(name)) {
          this.treeData[data.type - 1].datasets.forEach(() => {
            this.treeData[data.type - 1].datasets.forEach(item => {
              if (JSON.stringify(item).includes(name)) {
                num = num + 1;
                name =
                  name.substring(0, name.indexOf('数据集')) + '数据集' + num;
              }
            });
          });
        }
        this.$nextTick(() => {
          let newChild = {
            id: Math.floor(Math.random() * 10000 + 10000000) + '',
            name,
            type: data.type,
            typeName: data.typeName
          };
          this.treeData[data.type - 1].datasets.push(newChild);
          this.$store.commit('common/setEditTextId', newChild.id);
        });
      }
    },
    // 复制数据集
    datasetCopyId(data) {
      if (data) {
        let arr = JSON.stringify(this.treeData[data.type - 1]);
        let num = 1;
        let newName = data.name;
        if (data.name.includes(' - 副本')) {
          this.treeData[data.type - 1].datasets.forEach(() => {
            this.treeData[data.type - 1].datasets.forEach(item => {
              if (JSON.stringify(item).includes(newName)) {
                num = num + 1;
                newName =
                  data.name.substring(0, newName.indexOf(' - 副本')) +
                  ' - 副本' +
                  num;
              }
            });
          });
        } else {
          newName = data.name + ' - 副本';
          if (arr.includes(newName)) {
            this.treeData[data.type - 1].datasets.forEach(() => {
              this.treeData[data.type - 1].datasets.forEach(item => {
                if (JSON.stringify(item).includes(newName)) {
                  num = num + 1;
                  newName =
                    newName.substring(0, newName.indexOf(' - 副本')) +
                    ' - 副本' +
                    num;
                }
              });
            });
          }
        }
        if (newName.length > 20) {
          this.$message.warning('复制数据集名称超出长度限制，请重新命名');
          let newChild = {
            id: Math.floor(Math.random() * 10000) + '',
            type: data.type,
            name: newName
          };
          this.copyId = data.id;
          this.treeData[data.type - 1].datasets.push(newChild);
          this.$store.commit('common/setEditTextId', newChild.id);
          return;
        }
        const params = {
          id: data.id,
          newName: newName,
          workspaceId: this.workspaceId
        };
        this.$service.data.datasetCopy(params).then(res => {
          if (res.data.status === 200) {
            this.getGroupData(this.workspaceId);
            this.$store.commit('data/setDatasetCopyId', false);
            this.$message.success(res.data.msg);
          }
        });
      }
    }
  }
};
</script>

<style lang="less" scoped>
.dataManager {
  height: 100%;
  display: flex;
  flex-direction: column;
  &-header {
    padding: 10px 20px;
    border-bottom: 1px solid #eee;
    &-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    &-btn {
      margin-left: 10px;
      font-size: 0;
      &:first-child {
        margin-left: 0;
      }
      &-group {
        display: flex;
      }
    }
  }
  &-content {
    flex: 1;
    overflow-y: auto;
  }
}

// 自定义节点
.custom-tree-node {
  display: flex;
  align-items: center;
  height: 38px;
  padding: 0 20px;
  color: #333;
  font-size: 18px;

  // 左侧toggle
  &-toggle {
    margin-right: 8px;
    &-icon {
      float: left;
      transition: transform 200ms ease-in-out;
      &.expanded {
        transform: rotate(90deg);
        color: #507cff;
      }
    }
  }
  &-text {
    flex: 1;
    margin-right: 20px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &-right {
    display: flex;
    align-items: center;
    .icon {
      margin-left: 10px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
}
.editText {
  /deep/.editText-ipt {
    &__inner {
      padding-right: 40px;
      height: 30px;
      line-height: 38px;
    }
    &-showWordLimit {
      right: 90px;
      font-size: 12px;
    }
    &-datasetCopy {
      margin-top: 43px;
    }
    .btn {
      margin: 0 10px;
    }
    .btn.cancel {
      position: absolute;
      right: 5px;
    }
  }
}

/deep/.el-tree-node__loading-icon {
  position: absolute;
  margin-top: 12px;
  margin-left: 4px;
}
</style>
